import React, {Component} from 'react';
import "styles/FundingBar.scss";
import {animated, Spring} from "react-spring/renderprops";
import VisibilitySensor from "react-visibility-sensor";

/**
 * PROPS:
 * name: string
 * goal: float
 * current: float
 */
class FundingBar extends Component {
    constructor(props) {
        super(props);
        this.state = {visible: false};
    }

    render() {
        const progress = this.props.current / this.props.goal * 100;

        const fromStyle = {
            width: '0%'
        };
        const toStyle = {
            width: `${Math.round(progress)}%`
        };

        return (
            <div className={"funding-bar-container" + (this.props.disabled ? ' disabled' : '')}>
                <div className="funding-title">
                    {this.props.name}
                </div>
                <VisibilitySensor
                    containment={document.getElementById('funding-window')}
                    partialVisibility={true}
                    onChange={v => this.handleVisibilityChange(v)}
                >
                    <div className="funding-bar">
                        {this.state.visible &&
                        <Spring
                            native
                            from={fromStyle}
                            to={toStyle}
                            delay={250}
                        >
                            {style =>
                                <animated.div className="progress" style={style}/>
                            }
                        </Spring>
                        }
                    </div>
                </VisibilitySensor>
                <div className="funding-progress">
                    reached CHF {this.props.current.toFixed(2)} of
                    CHF {this.props.goal.toFixed(2)} ({Math.round(progress)}%) so far!
                </div>
            </div>
        );
    }

    handleVisibilityChange(v) {
        if (v !== this.state.visible) {
            this.setState({visible: v});
        }
    }
}

FundingBar.defaultProps = {
    current: 0,
    goal: 0
};

export default FundingBar;