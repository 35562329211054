import React from 'react';
import 'styles/App.scss';
import FundingBar from "./components/FundingBar";

function App() {
    return (
        <div className="App">
            <div className="window" id="funding-window">
                <div className="window-inner">
                    <h1 className="title">
                        The Future of Guxflix
                    </h1>
                    <p className="funding-message">
                        Dear Guxflix users,
                        <br/>
                        this month, Guxflix will be celebrating it's first birthday.
                        The service is almost a year old, and a lot has changed since the early days,
                        where it was still known as <strong>Kektus Media</strong>. Since that time, the library grew
                        immensely:
                        almost <strong>150 movies</strong>, and over <strong>700 episodes</strong> of various
                        TV shows joined the Guxflix family. All in all, that's now
                        almost <strong>5 Terabytes</strong> worth of media.
                        Guxflix grew so big that it even had to change homes: it outgrew
                        it's original home, a docker container on Kektus itself and moved into
                        a much needed separate server.
                        This impressive media collection was also used quite extensively -
                        over <strong>170 hours</strong> of movies were watched (around 130 movies),
                        and over <strong>2350 hours</strong> of TV shows with around 1465 episodes watched.
                        Amongst the movies, the most watched was <strong>Avengers:
                        Endgame</strong> with <strong>7</strong> plays,
                        while the most watched show
                        was <strong>The Office</strong> with an
                        astonishing view count of <strong>788</strong>.
                        <br/>
                        Of course, running and maintaining such a service has it's costs. Neither Guxflix, nor Kektus
                        Labs
                        profit from providing this service in any way. In order to provide you with the newest movies
                        and TV shows, Guxflix relies on the <strong>Usenet</strong>, which requires a subscription for
                        access.
                        This subscription will be ending on <strong>June 11th</strong>.
                        Another obstacle lies in <strong>storage capacity.</strong> As mentioned above, Guxflix now
                        features
                        a rich, 5 TB library of various media. Unfortunately, this fills up <strong>92%</strong> of the
                        available
                        storage capacity. For the library to grow further, new hard drives will be required.
                        <br/><br/>
                        If you use and enjoy the services Guxflix provides, and want to keep using them,
                        please consider helping fund this next year of Guxflix
                        - to make the next birthday even better!
                        <br/><br/>
                        If you want to help, please contact me - anyone who uses the service knows how to reach me.
                    </p>

                    <FundingBar
                        name="Usenet Subscription - COMPLETED!"
                        current={94}
                        goal={94}
                    />
                    <FundingBar
                        name="New Hard Drive"
                        current={31}
                        goal={121}
                    />
                </div>
            </div>

            <span className="copyright">© 2020 kektus labs</span>
        </div>
    );
}

export default App;